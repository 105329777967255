const sampleInitialValue = {
  // 헤더값
  headerType: 'normal', // 헤더 타입 (normal, logo)
  title: 'Main title', //  헤더 타이틀값
  isSticky: false, // 헤더가 항상 위에 존재하는지 유무
  btnText: '', // 텍스트 버튼 값 + 유무

  // headerType이 normal일 때만 동작
  isViewAdd: false, // 더하기 버튼 유무
  isViewAppClose: false, // 닫기 버튼 유무
  isViewInfo: false, // 정보 버튼 유무
  isViewMore: false, // 더보기 버튼 유무

  //  headerType이 logo일 때만 동작
  isViewSearch: false, // 검색 버튼 유무
  isViewAlarm: false, // 알람 버튼 유무
  hasAlarm: false, // 알람버튼이 true 일 때, 알람 유무
};

export default {
  // 부동산
  PGIFPMCAllianceRealestateTutorial001: Object.assign({}, sampleInitialValue, {
    title: '부동산',
  }),

  //자동차
  PGIFPMCAllianceCarMain001: Object.assign({}, sampleInitialValue, {
    title: '자동차',
    btnText: ' 내 차고',
  }),
  PGIFPMCAllianceCarMain003: Object.assign({}, sampleInitialValue, {
    title: '내 차 팔기',
  }),
  PGIFPMCAllianceCarMainEstimate001: Object.assign({}, sampleInitialValue, {
    title: '받은 견적',
  }),
  pgifpmcalliancecarmainestimatedetail001: Object.assign({}, sampleInitialValue, {
    title: '견적',
  }),
  PGIFPMCAllianceCarMandatoryAgreeInduction001: Object.assign({}, sampleInitialValue, {
    headerType: 'normal',
    title: '',
  }),
  PGIFPMCAllianceCarMainRegisterCarNo001: Object.assign({}, sampleInitialValue, {
    title: '',
  }),
  PGIFPMCAllianceCarMainNewCarEstimate001: Object.assign({}, sampleInitialValue, {
    title: '',
  }),
  PGIFPPCAllianceCarMainMyCarDetailChangeDetail001: Object.assign({}, sampleInitialValue, {
    title: '세부 모델 선택',
  }),
  PGIFPMCAllianceCarMainMyCarDetail001: Object.assign({}, sampleInitialValue, {
    title: '111가 1111',
  }),
  PGIFPMCAllianceCarMainMyGarage001: Object.assign({}, sampleInitialValue, {
    title: '내 차고',
  }),
  PGIFPMCAllianceCarMainSellMyCar001: Object.assign({}, sampleInitialValue, {
    title: '내 차 팔기',
  }),
  PGIFPMCAllianceCarMainNewCarEstimateSelectCar001: Object.assign({}, sampleInitialValue, {
    title: '차 선택',
  }),
  PGIFPMCAllianceCarMainInsuranceSelectMonth001: Object.assign({}, sampleInitialValue, {
    title: '',
  }),
  PGIFPMCAlianceCarMainNewCarEstimateSelectCarDetail001: Object.assign({}, sampleInitialValue, {
    title: '차 선택',
  }),
  PGIFPMCAllianceCarMainNewCarEstimateRequest001: Object.assign({}, sampleInitialValue, {
    title: '상세 정보',
    isSticky: true,
  }),

  // 환전지갑
  PGIFPMCAllianceExWalletIndex001: Object.assign({}, sampleInitialValue, {
    title: '환전지갑',
  }),

  //신용점수
  PGIFPPCAllianceCreditmanageMainFAQ001: Object.assign({}, sampleInitialValue, {
    title: '',
  }),
  PGIFPMCAllianceCreditmanageMainRaiseScore001: Object.assign({}, sampleInitialValue, {
    title: '신용점수 올리기',
    isViewInfo: true,
  }),
  PGIFPMCAllianceCreditmanageIntroduction001: Object.assign({}, sampleInitialValue, {
    title: '신용관리',
  }),
  PGIFPMCAllianceCreditmanageMainMyCreditScore001: Object.assign({}, sampleInitialValue, {
    title: '나의 신용점수',
  }),
  PGIFPMCAllianceCreditmanageMainMyCardAnalysis001: Object.assign({}, sampleInitialValue, {
    title: '카드 사용 분석',
  }),
  PGIFPMCAllianceCreditmanageMainMyCreditInfo001: Object.assign({}, sampleInitialValue, {
    title: '나의 자산 내역',
  }),
  PGIFPMCAllianceCreditmanageMain001: Object.assign({}, sampleInitialValue, {
    title: '신용관리',
  }),
  PGIFPMCAllianceCreditmanageMainMyCreditAnalysis001: Object.assign({}, sampleInitialValue, {
    title: '나의 신용분석',
  }),
  PGIFPMCAllianceCreditmanageMainRaiseScoreSubmitting001: Object.assign({}, sampleInitialValue, {
    title: '',
  }),
  PGIFPMCAllianceCreditmanageMainRaiseScoreSubmitHistory001: Object.assign({}, sampleInitialValue, {
    title: '제출내역',
  }),
};
